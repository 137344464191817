<script setup>
import { computed } from 'vue';

const emit = defineEmits(['input']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    value: {
        default: null,
    },
});

const proxyChecked = computed({
    get() {
        return props.checked;
    },

    set(val) {
        emit('input', val);
    },
});
</script>

<template>
    <input
        type="checkbox"
        :value="value"
        v-model="proxyChecked"
        class="rounded border-stone-300 dark:border-gray-700 text-region-forest shadow-sm focus:ring-region-forest"
    />
</template>
